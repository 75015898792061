.register {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100vw;
  padding-top: 10vh;
  padding-bottom: 10vh;
  padding-left: 15vw;
  padding-right: 15vw;
  align-items: center;
}

.form {
  /* flex: 1; */
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}

.form > * {
  width: 100%;
}

.label {
  font-size: 1.3rem;
}

.toolbar {
  --background: transparent !important;
  --ion-color-base: transparent !important;
}

.buttons {
  display: flex;
}

/* .profile-picture {
   position: relative;
  width: 170px;
  height: 170px;
  margin-bottom: 4rem; 
} */

.icon {
  position: absolute;
  border-radius: 50%;
  right: -10px;
  bottom: -5px;
}

/* .profile-picture img {
  width: 170px;
  height: 170px;
  object-fit: cover;
  border-radius: 50%;
} */

.avatar-button:hover {
  cursor: pointer;
}

.modal .modal-wrapper {
  --min-height: 100vh !important;
  --min-width: 100vw !important;
  --max-height: 100vh !important;
  --max-width: 100vw !important;
  --height: 100vh !important;
  --width: 100vw !important;
}
