.empty-conversations {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
}

.empty-conversations ion-icon {
  font-size: 4rem;
}

.empty-conversations ion-text {
  font-size: 1.2rem;
  text-align: center;
  width: 70vw;
}
