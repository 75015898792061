.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.image-cropper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.footer {
  padding: 0.5rem;

  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.react-crop {
  height: 100%;
}
