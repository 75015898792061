.mediarow {
  height: auto;
  padding-top: 0;
  padding-bottom: 0;
}

.month {
  font-size: 120%;
  text-align: left;
  padding-left: 1%;
}


.mediacard {
  height: 100%;
  max-height: 180px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}