.controls {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  transition: 0.2s;
}

.controls {
  color: white;
}

.play-pause {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.play-pause > * {
  font-size: 4rem;
}

.range-slider-expand {
  display: flex;

  padding: 3px;
}

.range-slider {
  flex: 1;
  padding-left: 5px;
  padding-right: 5px;
}

.range-slider > input {
  width: 100%;
}

.slider-volume {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.slider-volume input {
  width: 100%;
}
