.list {
  width: 100%;
  height: 100%;
}

.contacts-list {
  background: var(--ion-background-color) !important;
  flex: 1;
}

.contacts-list ion-item-divider {
  border-bottom: 0px solid black;
}

.contacts-list > ion-item {
  --background: var(--ion-background-color) !important;
}

.contacts-list > ion-item:hover {
  --background: var(--ion-item-background) !important;
  cursor: pointer;
}

.warning {
  display: flex;
  align-items: center;
  padding: 0.3rem;
}

.warning-icon {
  padding-right: 0.2rem;
}
