.member-page {
  height: 100%;
  width: 100%
}
.icon {
  padding-right: 1%;
}

.member-title {
  font-size: x-large;
}

.member-name {
  font-size: x-large;
}