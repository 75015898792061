.toolbar {
  --background: rgb(0, 0, 0) !important;
  color: var(--ion-color-primary-contrast)
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
}

.content img {
  object-fit: cover;
}