.read {
  font-size: 0.7rem !important;
  color: var(--ion-color-medium) !important;
}

.bubble {
  border-radius: 8px;
  margin-bottom: 0.2rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.text {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.3rem;
}

.message-text {
  word-break: keep-all !important;
  white-space: nowrap;
  padding-top: 0.2rem;
}

.file {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  padding-top: 0.3rem;
}

.space {
  height: 0.5rem;
  width: 100%;
}

.author-name {
  flex-shrink: 0;
  margin-left: calc(1.8rem + 0.5rem + 0.5rem + 0.5rem);
}

.picture {
  flex-shrink: 0;
  height: 100%;
  width: 100%;
  max-height: 35px;
  max-width: 35px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 0.6rem;
  padding-right: 2.5rem;
}

.picture > img {
  height: calc(1.1rem + 0.5rem + 0.5rem);
  width: calc(1.1rem + 0.5rem + 0.5rem);
}

.avatar-container {
  height: 100% !important;
  max-height: 35px;
  max-width: 35px;
  width: 35px;
}

.me-container {
  --width: auto !important;
  align-self: flex-end;
  display: flex;
  align-items: flex-start;
  max-width: 75%;
  /* min-width: 60%; */
}

.me {
  background: var(--ion-color-primary);
}

.max-file {
  width: 30%;
}

.others-container {
  --width: auto !important;
  align-self: flex-start;
  display: flex;
  align-items: flex-start;
  max-width: 75%;
}

.others {
  background: var(--ion-color-light);
}

.others p {
  color: var(--ion-color-dark-tint);
  font-size: 1rem;
}

.others h6 {
  color: var(--ion-color-dark-tint);
  font-size: 0.7rem;
}

.me p {
  color: var(--ion-color-primary-contrast);
  font-size: 1rem;
}

.me span {
  color: var(--ion-color-primary-contrast);
  font-size: 1rem;
}

.me h6 {
  color: var(--ion-color-primary-contrast);
  font-size: 0.7rem;
}

.me ion-icon {
  padding-left: 1px;
}

.avatar {
  filter: invert(0%);
}

@media (prefers-color-scheme: dark) {
  .avatar {
    filter: invert(100%);
  }
}

.not-delivered {
  flex-direction: row-reverse;
  font-size: small;
}

.not-delivered-container {
  --width: auto !important;
  align-self: flex-end;
  display: flex;
  align-items: flex-start;
  max-width: 80%;
  padding-top: 0px;
  padding-bottom: 0.2rem;
  padding-right: 3rem;
  margin-top: -0.3rem;
}
