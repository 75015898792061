.header {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}

.toolbar-container {
  width: 100%;
}

.profile-picture {
  width: auto;
  height: 12rem;
}

.profile-picture-toolbar {
  margin-top: 1rem;
  height: 25vh;
  align-items: center;
  width: auto;
}

.nickname {
  font-size: x-large;
}

.header ion-toolbar {
  --background: rgba(0, 0, 0, 0);
}

.profile-toolbar {
  height: 10vh;
  display: flex;
  flex-direction: column;
  /* --background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.7)
  ) !important; */
  justify-content: flex-end;
}

.content-icon {
  padding-right: 1.5rem;
  font-size: 2.5rem;
}

@media only screen and (max-width: 768px) {
  .content {
    flex-direction: column;
  }
  .content-icon {
    font-size: 4rem;
  }
}

.avatar-content {
  height: 100px;
  width: 100px;
  position: relative;
}

.avatar {
  width: 100%;
  height: 100%;

  position: relative;
}

.image-container {
  position: relative;
  border-radius: 50%;
  width: 100px;
  height: 100%;
  overflow: hidden;
}

.img {
  filter: invert(0%);
}

@media (prefers-color-scheme: dark) {
  .img {
    filter: invert(100%);
  }
}

.overlay {
  position: absolute;
  display: flex;
  align-items: center;
  border-radius: 50%;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  z-index: 100;
}

.overlay:hover {
  opacity: 0.7;
  cursor: pointer;
}

.icon-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: -10px;
  padding: 8px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--ion-color-light);
  z-index: 100;
}
