.identifier {
  padding-bottom: -0.5rem !important;
  padding-top: -0.5rem !important;
}

.avatar {
  filter: invert(0%);
}

@media (prefers-color-scheme: dark) {
  .avatar {
    filter: invert(100%);
  }
}
