.label {
  color: var(--ion-color-medium);
  font-size: small;
  padding-left: 5px;
}

.avatar {
  padding-right: 1rem;
}

.container {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  display: flex;
  align-items: center;
}