.title {
  overflow: hidden;
  text-overflow: ellipsis;
}
.title-container {
  display: flex;
}

@media (prefers-color-scheme: dark) {
  .avatar {
    filter: invert(100%);
  }
}