.file:hover {
  cursor: pointer;
}

.file-name {
  word-break: break-all;
}

.file-size {
  color: var(--ion-color-dark-tint);
}

.file-icon-dark {
  color: var(--ion-color-dark-tint);
}

.file-icon-light {
  color: var(--ion-color-primary-contrast);
}
