.content {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  color: white;
}

.play-pause {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-pause > * {
  font-size: 4rem;
}

.modal-control {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  transition: 0.5s;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
}

.modal-control:hover {
  opacity: 1 !important;
}

.slider-volume {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.slider-volume input {
  width: 100%;
}

.toolbar {
  --background: rgb(0, 0, 0) !important;
  color: var(--ion-color-primary-contrast);
}

.footer {
  --background: var(--ion-color-medium);
}
