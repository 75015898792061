.reply-to {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 0.2rem;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}

.content-me {
  color: var(--ion-color-primary-contrast);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.content-others {
  color: var(--ion-color-dark-tint);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.content-me p {
  margin: 0;
  font-size: 0.85rem !important;
}

.content-others p {
  margin: 0;
  font-size: 0.85rem !important;
}

.content-me ion-label {
  font-size: 1rem !important;
  font-weight: bold;
}
.content-others ion-label {
  font-size: 1rem !important;
  font-weight: bold;
}

.thumbnail {
  border-radius: 5px;
  overflow: hidden;
}

.vertical-divider-me {
  width: 2px !important;
  border-radius: 2px;
  background-color: var(--ion-color-primary-contrast);
  align-self: stretch;
  top: 0.1rem;
}

.vertical-divider-others {
  width: 2px !important;
  border-radius: 2px;
  background-color: var(--ion-color-dark-tint);
  align-self: stretch;
  top: 0.1rem;
}
