.contacts-list {
  background: var(--ion-background-color) !important;
}

.add-label {
  font-size: medium;
  text-transform: none;
}

.my-custom-modal-css .modal-wrapper {
  height: 20%;
  top: 80%;
  position: absolute;
  display: block;
}

.modal {
  padding: 10px;
  background-color: red;
}