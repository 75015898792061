.input input {
  /* width: 100% !important; */
  font-size: 1rem !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-bottom: 1px solid var(--ion-color-medium-shade) !important;
}

.error-input input {
  border-bottom: 1px solid var(--ion-color-danger) !important;
}

.error-container {
  height: 45px;
}

.error {
  font-size: 0.8rem !important;
}
