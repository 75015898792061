.login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100vw;
  padding-top: 10vh;
  padding-bottom: 10vh;
  padding-left: 15vw;
  padding-right: 15vw;
  align-items: center;
}

.form {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.form > * {
  width: 100%;
}

.label {
  font-size: 1.3rem;
}

.form input {
  /* width: 100% !important; */
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-bottom: 1px solid var(--ion-color-medium-shade) !important;
}

.toolbar {
  --background: transparent !important;
  --ion-color-base: transparent !important;
}
