.menu ion-list {
  background: var(--ion-background-color) !important;
}

/* .avatar {
  filter: invert(0%);
  width: 50px;
  height: 50px;
} */

.menu ion-item,
.menu ion-item-group {
  --background: var(--ion-background-color) !important;
}

.menu ion-item:hover,
.menu ion-item-group:hover {
  --background: var(--ion-item-background) !important;
  cursor: pointer;
}
.menu {
  display: flex;
}

.container {
  align-items: center;
  display: flex;
  position: relative;
  width: auto;
}

.identicon {
  position: absolute;
  bottom: 0;
}

@media (prefers-color-scheme: dark) {
  .avatar {
    filter: invert(100%);
    width: 60px;
    height: 60px;
    margin-right: 16px !important;
    max-height: 150%;
    max-width: 150%;
  }
}

.version {
  font-size: small;
  color: var(--ion-color-medium);
}
