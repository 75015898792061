.chatdetails > ion-slides {
    height: 100%
}

.titlebar {
    height: 200px;
    display: flex;
    flex-direction: column-reverse;
}

.title {
    font-size: 200%;
    font-weight: bold;
    padding-left: 2%;
}

.month {
    font-size: 120%;
    text-align: left;
    padding-left: 1%;
}

.mediarow {
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
}

.mediacard {
    height: 100%;
    max-height: 180px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.mediadiv {
    height: 100%;
}

.mediadiv > ion-img {
    height: 100%;
    max-width: 200%;
    object-fit: cover;
}

.filelist {
    width: 100%;
}

.filecard {
    width: 100%;
}