.header {
  /* background: var(--ion-background-color); */
  /* height: 30%; */
  display: flex;
  flex-direction: column;
}

.groupname {
  display: flex;
  font-size: x-large;
  padding-left: 7%;
  padding-bottom: 2%;
  padding-top: 15%;
}

.avatar-container {
  position: relative;
  width: 100%;
  height: 100px;

  display: flex;
  justify-content: center;
}

.avatar {
  width: 100%;
  height: 100%;

  position: relative;
}

.avatar-content {
  height: 100%;
  width: 100px;
  position: relative;
}

.image-container {
  position: relative;
  border-radius: 50%;
  width: 100px;
  height: 100%;
  overflow: hidden;
  /* border: 1px solid rgb(var(--ion-color-primary-rgb)); */
}

.image-container-set {
  position: relative;
  border-radius: 50%;
  width: 100px;
  height: 100%;
  overflow: hidden;
}

.icon-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: -10px;
  padding: 8px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--ion-color-light);
  z-index: 100;
}

.overlay {
  position: absolute;
  display: flex;
  align-items: center;
  border-radius: 50%;
  left: 0;
  top: 0;
  opacity: 0;

  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  z-index: 100;
}

.overlay:hover {
  opacity: 0.7;
  cursor: pointer;
}

.image-container:hover {
  cursor: pointer;
}

.img {
  filter: invert(0%);
}

@media (prefers-color-scheme: dark) {
  .img {
    filter: invert(100%);
  }
}
