.toolbar {
  display: flex;
  flex-direction: row;
}

.file-view {
  display: flex;
  height: 100%;
  flex-direction: row;
}

.col > * {
  position: absolute;
  right: -5px;
  top: -5px;
}

.col > *:hover {
  cursor: pointer;
}

.close-button {
  position: absolute;
  right: -3px;
  top: -3px;
}

.close-button:hover {
  cursor: pointer;
}

.textarea {
  font-size: 0.97rem;
}


.card {
  position: relative;
  display: flex;
  flex-direction: column;
}

.blackborder {
  border-color: black;
  border-width: 2px;
  border-style: outset;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 200px;
}