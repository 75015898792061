.landing {
  display: flex;
  height: 100%;
  width: 100%;
  padding-top: 5%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.logo {
  height: 25%;
  width: 40vw;
}

.landingImage {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 10vh;
  padding-bottom: 25vh;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-label {
  font-size: 0.9rem;
}
