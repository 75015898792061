.chat {
  /* background: var(--ion-background-color); */
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title-container {
  display: flex;
}

.avatar {
  width: 70px;
  height: 70px;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: -20px !important;
}

.chat > ion-item {
  --background: var(--ion-background-color);
}
